import React from 'react';
import smm from './img/smm.png';
import cm from './img/cm.png';
import em from './img/em.png';
import ppc from './img/ppc.png';
import seo from './img/seo.png';
import webdev from './img/webdev.png';
const Services = () => {
  return (
    <section id="services" className="section__container service__container">
      <p className="section__subheader">Our Services</p>
      <h2 className="section__header">Our Comprehensive Digital Solutions</h2>
      <div className="service__grid">
        {/* Service Card 1 */}
        <div className="service__card">
          <div className="service__icon">
          <img src={seo} alt="bg" className="bgImage" />
          </div>
          <h4>Search Engine Optimization (SEO)</h4>
          <p>
            Optimizing websites to improve their visibility on search engines,
            driving organic traffic and enhancing online presence.
          </p>
          <a href="#">
           <span><i className="ri-arrow-right-line"></i></span>
          </a>
        </div>

        {/* Service Card 2 */}
        <div className="service__card">
          <div className="service__icon">
          <img src={smm} alt="bg" className="bgImage" />

          </div>
          <h4>Social Media Marketing (SMM)</h4>
          <p>
            Managing and executing marketing campaigns on social media platforms to build brand awareness,
            engage with the audience, and drive conversions.
          </p>
          <a href="#">
            <span><i className="ri-arrow-right-line"></i></span>
          </a>
        </div>

        {/* Service Card 3 */}
        <div className="service__card">
          <div className="service__icon">
          <img src={ppc} alt="bg" className="bgImage" />
          </div>
          <h4>Pay-Per-Click Advertising (PPC)</h4>
          <p>
            Creating and running targeted ads on platforms like Google Ads and social media, where advertisers pay a fee each time their ad is clicked.
          </p>
          <a href="#">
             <span><i className="ri-arrow-right-line"></i></span>
          </a>
        </div>

        {/* Service Card 4 */}
        <div className="service__card">
          <div className="service__icon">
          <img src={cm} alt="bg" className="bgImage" />
          </div>
          <h4>Content Marketing</h4>
          <p>
            Developing and distributing valuable content to attract and engage a target audience, fostering brand loyalty and driving customer actions.
          </p>
          <a href="#">
             <span><i className="ri-arrow-right-line"></i></span>
          </a>
        </div>

        {/* Service Card 5 */}
        <div className="service__card">
          <div className="service__icon">
          <img src={em} alt="bg" className="bgImage" />
          </div>
          <h4>Email Marketing</h4>
          <p>
            Implementing email campaigns to nurture leads, promote products or services, and maintain communication with the audience.
          </p>
          <a href="#">
             <span><i className="ri-arrow-right-line"></i></span>
          </a>
        </div>

        {/* Service Card 6 */}
        <div className="service__card">
          <div className="service__icon">
          <img src={webdev} alt="bg" className="bgImage" />
          </div>
          <h4>Web Design and App Development</h4>
          <p>
            Designing and developing visually appealing, user-friendly websites that support digital marketing efforts and enhance the overall user experience.
          </p>
          <a href="#">
            <span><i className="ri-arrow-right-line"></i></span>
          </a>
        </div>

      </div>
    </section>
  );
};

export default Services;
