import React from 'react';
import { RiMailFill, RiPhoneFill, RiInstagramLine } from 'react-icons/ri';

const Footer = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <footer className="section__container footer__container">
        <div className="footer__grid">
          <div className="footer__col">
            <h4>Turning Visions into Digital Reality. Your Path to Online Success Starts Here.</h4>
            <p>
              <span><RiMailFill /></span> <a href="mailto:funkyfusionmedia@gmail.com">funkyfusionmedia@gmail.com</a><br />
              <span><RiMailFill /></span> <a href="mailto:media@funkyfusionmedia.com">media@funkyfusionmedia.com</a>
            </p>
            <p>
              <span><RiPhoneFill /></span> <a href="tel:+918968751871">+91 8968751871</a> <br />
              <span><RiPhoneFill /></span> <a href="tel:+917973214178">+91 7973214178</a>
            </p>
          </div>
          <div className="footer__col">
            <h4>About</h4>
            <p><a href="#home">Home</a></p>
            <p><a href="#news">News</a></p>
            <p><a href="#targetSection">Get-In-Touch</a></p>
          </div>
          <div className="footer__col">
            <h4>Features</h4>
            <p><a href="#about-us"> About-Us </a></p>
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
          <div className="footer__col">
            <h4>Contact</h4>
            <p><a href="javascript:void(0);" onClick={() => scrollToSection('targetSection')}>Department</a></p>
            <p><a href="javascript:void(0);" onClick={() => scrollToSection('targetSection')}>Media</a></p>
            <p><a href="javascript:void(0);" onClick={() => scrollToSection('targetSection')}>Support</a></p>
          </div>
        </div>
      </footer>
      <div className="section__container footer__bar">
        <p>Copyright © 2023. All rights reserved.</p>
        <div className="socials">
          <a href="https://www.instagram.com/funkyfusionmedia?igsh=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
            <span><RiInstagramLine /></span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
