import React, { useState } from 'react';
import Navbar from './Navbar';
import ContactSection from './ContactSection';
import Header from './Header';
import InstaHighlights from './InstaHighlights';
import NewsComponent from './NewsComponent';
import Testimonial from './Testiomonial';  // Keep only one import
import Faq from './Faq';
import Footer from './Footer';
import AboutUsSection from './AboutUsSection';
import Services from './Services';
import Scroll from './Scroll';
import ScrollProgress from './ScrollProgress';
import Seo from './seo';

import ReactGA from "react-ga";

ReactGA.initialize('G-2WVTYLJP3P');
function App() {



  const [showContactSection, setShowContactSection] = useState(false);

  const handleGetInTouchClick = () => {
    setShowContactSection(true);
  };

  return (
    <div className="App">
      <ScrollProgress />
      <Scroll />
      <Navbar onGetInTouchClick={handleGetInTouchClick} />
      <Header />
      <Services />
      <AboutUsSection />
      <InstaHighlights />
      <NewsComponent />
      <Seo/>
      <Testimonial />
      <ContactSection/>
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
