import React, { useEffect } from 'react';

function ScrollProgress() {
  useEffect(() => {
    const scrollFunction = () => {
      // Get the scroll position as a percentage of the total scroll height
      const scrollPosition = (document.documentElement.scrollTop + window.innerHeight) / document.documentElement.scrollHeight * 100;

      // Update the width and height of the progress bar
      const progressBar = document.getElementById("scroll-progress-bar");
      if (progressBar) {
        progressBar.style.width = scrollPosition + "%";
        progressBar.style.height = "5px";
      }

      // Show/hide the progress bar container based on scroll position
      const progressContainer = document.getElementById("scroll-progress-container");
      if (progressContainer) {
        if (scrollPosition > 20) {
          progressContainer.style.display = "block";
        } else {
          progressContainer.style.display = "none";
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', scrollFunction);

    // Cleanup function
    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <div id="scroll-progress-container" style={{ display: 'none' }}>
      <div id="scroll-progress-bar"></div>
    </div>
  );
}

export default ScrollProgress;
