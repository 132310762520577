import React, { useEffect } from 'react';
import tamplate from './img/template.jpg';
import { initializeCursorAnimation } from './CursorAnimation'; // Adjust the path accordingly
import gsap from 'gsap';

const AboutUsSection = () => {
  useEffect(() => {
    initializeCursorAnimation(); // Initialize the cursor animation when the component mounts

    // Additional useEffect code if needed

    return () => {
      // Cleanup code if needed
    };
  }, []);

  const handleMouseEnter = () => {
    const cursor = document.getElementById('cursor');
    if (cursor) {
      cursor.classList.add('grow');
    }
  };

  const handleMouseLeave = () => {
    const cursor = document.getElementById('cursor');
    if (cursor) {
      cursor.classList.remove('grow');
    }
  };

  return (
    <section
      id="about-us"
      className="section__container template__container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="template__content cursor-scale">
        <p className="section__subheader" id="explore-text">
          Explore-Us
        </p>
        <h2 className="section__header" id="header-text">
          Accelerate Your Digital Success
        </h2>
        <p className="description" id="description-text">
          "Welcome to FunkyFusion Media, your partner in digital excellence with over 2 years of expertise. Specializing in crafting fast, impactful websites and offering a suite of digital marketing services, including eCommerce startups, web design, video editing, and SEO. Our mission is to accelerate your online success through innovative solutions tailored to your unique needs. Join us on the journey to elevate your brand and leave a lasting digital impression."
        </p>
      </div>
      <div className="template__image cursor-scale">
        <img src={tamplate} alt="bg" className="bgImage" />
      </div>
    </section>
  );
};

export default AboutUsSection;
