import React, { useRef, useEffect } from 'react';
import { initializeCursorAnimation } from './CursorAnimation';

function Header() {
  const headerText = "Transforming Visions into, Digital Reality.";
  const headerElementRef = useRef(null);
  const spanElementRef = useRef(null);
  let isErasing = false;
  let typingTimeout;

  const typeWriter = (text, i) => {
    const headerElement = headerElementRef.current;
    const spanElement = spanElementRef.current;

    if (!isErasing && i <= text.length) {
      headerElement.textContent = text.substring(0, i + 1);
      i++;
      if (text.charAt(i - 1) === ',') {
        spanElement.innerHTML = '<br />';
      }
      typingTimeout = setTimeout(() => {
        typeWriter(text, i);
      }, 100);
    } else if (isErasing && i >= 0) {
      headerElement.textContent = text.substring(0, i);
      i--;

      if (text.charAt(i) === 'T') {
        isErasing = false;
      }

      typingTimeout = setTimeout(() => {
        typeWriter(text, i);
      }, 50);
    } else {
      isErasing = !isErasing;

      setTimeout(() => {
        typeWriter(text, isErasing ? text.length : 0);
      }, 500);
    }
  };

  useEffect(() => {
    initializeCursorAnimation();
    typeWriter(headerText, 0);

    // Cleanup function
    return () => {
      clearTimeout(typingTimeout);
    };
  }, []);

  const handleMouseEnter = () => {
    // Your existing code for mouse enter
  };

  const handleMouseLeave = () => {
    // Your existing code for mouse leave
  };

  return (
    <div>
      <div className="cursor"></div>
      <header className="section__container header__container">
        <div className="auto-typing-container">
          <h1
            className="section__header cursor-scale"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={headerElementRef}
          >
            <span ref={spanElementRef}></span>
          </h1>
        </div>
        <p className="cursor-scale">Turning visions into digital reality. Experts in web development, SEO, e-commerce, and innovative app solutions for online success and growth</p>

        <div className="tooltip">
          <div className="item-hints">
            <div className="hint" data-position="4">
              <span className="hint-radius"></span>
              <span className="hint-dot">MSME</span>
              <div className="hint-content do--split-children">
                <p>Our company has obtained MSME verification.</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
