import React, { useState, useEffect } from 'react';
import { initializeCursorAnimation } from './CursorAnimation';
import gsap from 'gsap';

const Faq = () => {

    const [activeIndex, setActiveIndex] = useState(null);
  
    const handleQuestionClick = (index) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  
    const handleMouseEnter = () => {
      // Handle mouse enter logic if needed
    };
  
    const handleMouseLeave = () => {
      // Handle mouse leave logic if needed
    };

    useEffect(() => {
      initializeCursorAnimation(); // Initialize cursor animation on component mount
    }, []); // Empty dependency array to run the effect only once

    return (
      <div>
        <div className="cursor"></div>
        <div className="quest">
          <section className="section__container question__container">
            <p className="section__subheader">Have Questions?</p>
            <h2 className="section__header">Frequently asked questions</h2>
            <div className="question__accordian">
              {faqData.map((item, index) => (
                <div
                  key={index}
                  className={`question__box ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => handleQuestionClick(index)}
                >
                  <div className="title cursor-scale">
                    <span>{item.question}</span>
                    <span className="icon">+</span>
                  </div>
                  <div className="content cursor-scale" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    );
  };

  const faqData = [
    {
      question: 'What services does your website design agency offer?',
      answer: 'Our website design agency offers a wide range of services including custom website design, responsive design, e-commerce development, content management system integration, search engine optimization (SEO), website maintenance, and ongoing support.',
    },
    {
      question: 'What is the typical timeline for completing a website design project?',
      answer: 'The timeline for completing a website design project depends on various factors such as the size of the website, functionality requirements, and the availability of content. On average, a typical website design project takes approximately 4-8 weeks from initial consultation to launch.',
    },
    {
      question: 'Do you provide ongoing website maintenance and support?',
      answer: 'Yes, we offer ongoing website maintenance and support services to ensure your website remains up-to-date, secure, and performs optimally. Our maintenance plans include regular updates, backups, security monitoring, and technical support.',
    },
    {
      question: 'Can you create a custom website design that aligns with our brand identity?',
      answer: 'Absolutely! We specialize in creating custom website designs that are tailored to reflect your unique brand identity. Our team works closely with you to understand your brand and design a website that captures your vision, values, and target audience.',
    },
    {
      question: 'Do you offer responsive design to ensure our website works well on mobile devices?',
      answer: 'Yes, responsive design is a crucial aspect of our website development process. We create websites that adapt seamlessly to different screen sizes and devices, ensuring optimal user experience and usability on desktops, tablets, and smartphones.',
    },
    {
      question: 'How does your agency approach content marketing to drive engagement?',
      answer: 'Our content marketing strategies focus on creating valuable and relevant content that resonates with your target audience. We utilize a combination of compelling storytelling, SEO optimization, and data-driven insights to enhance engagement and drive meaningful interactions.',
    },
    {
      question: 'What types of online advertising services does your agency provide?',
      answer: 'We offer a comprehensive range of online advertising services, including but not limited to pay-per-click (PPC) campaigns, display advertising, social media advertising, and remarketing. Our goal is to maximize your online visibility and deliver measurable results for your advertising investment.',
    },
    {
      question: 'How does your agency determine the cost per click (CPC) in advertising campaigns?',
      answer: 'The cost per click (CPC) is determined based on various factors such as the chosen advertising platform, target audience, keyword competitiveness, and campaign objectives. We conduct thorough research and strategic planning to optimize CPC and ensure cost-effective advertising solutions for your business.',
    }
  ];
export default Faq;
