import React from 'react';

function Seo() {

  const codeSnippet = `<IfModule mod_deflate.c>
  # Enable Gzip compression for various file types
  AddOutputFilterByType DEFLATE text/plain text/html text/xml
  AddOutputFilterByType DEFLATE text/css text/javascript application/javascript application/x-javascript
  AddOutputFilterByType DEFLATE application/xml application/xhtml+xml application/xml-dtd
  AddOutputFilterByType DEFLATE application/rss+xml application/atom+xml
  AddOutputFilterByType DEFLATE application/vnd.ms-fontobject application/x-font application/x-font-opentype
  AddOutputFilterByType DEFLATE application/x-font-otf application/x-font-truetype application/x-font-ttf
  AddOutputFilterByType DEFLATE image/svg+xml image/x-icon

  # Remove browser bugs related to gzip compression
  BrowserMatch ^Mozilla/4 gzip-only-text/html
  BrowserMatch ^Mozilla/4\\.0[678] no-gzip
  BrowserMatch \\bMSIE !no-gzip !gzip-only-text/html

  # Set compression level (optional, adjust as needed)
  DeflateCompressionLevel 6

  # Force compression for certain file types
  <FilesMatch "\\\.(js|css|html|xml|txt|svg)$">
      SetOutputFilter DEFLATE
  </FilesMatch>

  # Add headers to indicate compression
  Header append Vary User-Agent

  # Enable browser caching for certain file types
  <IfModule mod_expires.c>
      ExpiresActive On
      ExpiresByType text/css "access plus 1 month"
      ExpiresByType text/javascript "access plus 1 month"
      ExpiresByType application/javascript "access plus 1 month"
      ExpiresByType image/x-icon "access plus 1 year"
      ExpiresByType image/svg+xml "access plus 1 year"
  </IfModule>

  # Enable HTTP/2 if available
  <IfModule mod_http2.c>
      Protocols h2 http/1.1
  </IfModule>

  # Minify CSS, JavaScript, and HTML
  <IfModule mod_pagespeed.c>
      ModPagespeed on
      ModPagespeedEnableFilters combine_css,combine_javascript
  </IfModule>

  # Implement image optimization
  <IfModule mod_pagespeed.c>
      ModPagespeedEnableFilters rewrite_images
  </IfModule>

  # Utilize a Content Delivery Network (CDN)

  # Enable server-side caching (example: OPcache for PHP)

  # Implement lazy loading for images and videos
</IfModule>`;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(codeSnippet);
  };

  return (
    <div className="ss">
      <div className="container mt-5 mb-5 container-center">
        <div className="row">
          <div className="col-md-6 offset-md-3">
           
            <h4 className="news">Seo Tools</h4>
            
            <div className="border-container">
           
              <p style={{ marginRight: 'auto', width: '100%' }}> <h1>WIX WEBSITE SPEED CODE</h1> <br></br>Optimize your Wix website's loading speed effortlessly with just one click! Enhance your site's performance twofold instantly. Streamline user experience and improve search engine ranking with our professional speed optimization solution. Experience faster loading times and seamless browsing for your visitors with our intuitive one-click solution. Elevate your website's performance and efficiency with ease. <br></br><br></br><b>wix-setting/cutsom-code/head part of your website</b></p>
             
              <button className="copyaa" onClick={handleCopyCode}>
                <span className="tooltipaa" data-text-initial="Copy to clipboard" data-text-end="Copied!"></span>
                <span>
                  <svg className="clipboardaa" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 6.35 6.35" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                    <g>
                      <path d="M2.43.265c-.3 0-.548.236-.573.53h-.328a.74.74 0 0 0-.735.734v3.822a.74.74 0 0 0 .735.734H4.82a.74.74 0 0 0 .735-.734V1.529a.74.74 0 0 0-.735-.735h-.328a.58.58 0 0 0-.573-.53zm0 .529h1.49c.032 0 .049.017.049.049v.431c0 .032-.017.049-.049.049H2.43c-.032 0-.05-.017-.05-.049V.843c0-.032.018-.05.05-.05zm-.901.53h.328c.026.292.274.528.573.528h1.49a.58.58 0 0 0 .573-.529h.328a.2.2 0 0 1 .206.206v3.822a.2.2 0 0 1-.206.205H1.53a.2.2 0 0 1-.206-.205z" fill="currentColor"></path>
                    </g>
                  </svg>
                  <svg className="checkmarkaa" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                    <g>
                      <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" fill="currentColor" data-original="#000000"></path>
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Seo;
