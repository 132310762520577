import React, { useState, useEffect, useRef } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import gsap from 'gsap';

const ContactSection = () => {
  const [state, handleSubmit] = useForm("mbjnlnek");
  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    let mouseX = 0;
    let mouseY = 0;

    gsap.to({}, 0.016, {
      repeat: -1,
      onRepeat: function () {
        gsap.set(cursor, {
          css: {
            left: mouseX,
            top: mouseY,
          },
        });
      },
    });

    const handleMouseMove = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleMouseEnter = () => {
    const cursor = cursorRef.current;
    if (cursor) {
      cursor.classList.add('grow');
    }
  };

  const handleMouseLeave = () => {
    const cursor = cursorRef.current;
    if (cursor) {
      cursor.classList.remove('grow');
    }
  };

  return (
    <div>
       <div id="contactSection">
      <div className="nocursor">
        <div className="cursor" ref={cursorRef}></div>
        <section className="section__container service__container" id="targetSection">
          <h2 className="section__header" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            Let's discuss together
          </h2>
          <div className="social">
            <div className="description" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <p className="con">
                Empowering your online presence with innovative solutions, we strive to provide exceptional services tailored to your unique needs, ensuring a seamless and captivating digital experience for your audience.
                <br /><br /><br />
                Feel free to provide more information, and I can help tailor the sentence to better fit your website's context and goals.
              </p>
            </div>
            <div className="form-container">
              {!state.succeeded ? (
                <form method='POST' onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="form-group">
                    <label htmlFor="email">Company Email</label>
                    <input required="" name="email" id="email" type="email" />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="textarea">How Can We Help You?</label>
                    <textarea required="" cols="50" rows="10" id="textarea" name="message"></textarea>
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                  </div>

                  
                  <div className="form-group upload-btn-wrapper">
                    
                    <input type="file" id="file" name="file" />
                  </div>
                  <button type="submit" className="form-submit-btn" disabled={state.submitting}>
                    Submit
                  </button>
                </form>
              ) : (
                <div id="thankYouMessage">
                  Thank you for reaching out!<br /><br /> Your submission is important to us. Anticipate a response from our dedicated team within the next 12 hours. We look forward to connecting with you!
                  <div aria-label="Orange and tan hamster running in a metal wheel" role="img" className="wheel-and-hamster">
                    <div className="wheel"></div>
                    <div className="hamster">
                      <div className="hamster__body">
                        <div className="hamster__head">
                          <div className="hamster__ear"></div>
                          <div className="hamster__eye"></div>
                          <div className="hamster__nose"></div>
                        </div>
                        <div className="hamster__limb hamster__limb--fr"></div>
                        <div className="hamster__limb hamster__limb--fl"></div>
                        <div className="hamster__limb hamster__limb--br"></div>
                        <div className="hamster__limb hamster__limb--bl"></div>
                        <div className="hamster__tail"></div>
                      </div>
                    </div>
                    <div className="spoke"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  );
};

export default ContactSection;
