
import React, { useState, useEffect } from 'react';
import { initializeCursorAnimation } from './CursorAnimation';
import img1 from './img/1.jpg';
import img2 from './img/2.jpeg';
import img3 from './img/3.jpg';
import commentIcon from './img/comment.png';
import heartIcon from './img/heart.png';
import shareIcon from './img/share.png';
import bookmarkIcon from './img/bookmark.png';
import phoenix from './img/phoenix.png';
import Bhanu from './img/Bhanu.png';
import lokesh from './img/lokesh.jpg';


const InstaHighlights = () => {
  const images = [Bhanu, lokesh, phoenix];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Function to change image every 3 seconds
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Set interval to change image every 3 seconds
    const intervalId = setInterval(changeImage, 3000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    // Check if the screen width is greater than or equal to 1024 pixels
    var isDesktop = window.matchMedia("(min-width: 1024px)").matches;

    // If it's a desktop screen, set up cursor animations
    if (isDesktop) {
      initializeCursorAnimation();
    }
  }, []);

  const likeButton = () => {
    // Implement your like button logic here
  };

    return (
        
        <section>
            <div className="cursor"></div>
            <p className="section__subheader">Insta Highlights</p>
            <h2 className="section__header cursor-scale" onMouseEnter={() => { }} onMouseLeave={() => { }}>
                Quick-Glimpse
            </h2>
            <div className="left-section">
                <div className="description-container">
                    <h3 className="follow">Follow-Us</h3>
                    <div className="Btnsize">
                        <button className="Btn">
                            <span className="svgContainer">
                                <a
                                    href="https://www.instagram.com/funkyfusionmedia?igsh=OGQ5ZDc2ODk2ZA=="
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <svg
                                        fill="white"
                                        className="svgIcon"
                                        viewBox="0 0 448 512"
                                        height="1.5em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                    </svg>
                                </a>
                            </span>
                            <span className="BG"></span>
                        </button>
                    </div>
                    <br />
                    <br />
                  <p  className="description1 cursor-scale" style={{ lineHeight: '1.8' }} >


                       "Elevate your digital marketing game with us at Funky Fusion Media! 👊 Follow us for the latest trends, updates, and exclusive content. Stay ahead in the world of digital marketing and amplify your strategies. Don't miss out! Visit our Page now!
                       
                       As the CEO of the Agency, Lokesh Kumar Pandit, I personally invite you to join our community and stay informed about the cutting-edge developments in the digital marketing landscape. Let's shape the future of marketing together!
                         <span style={{ color: 'blue' }}>#FunkyFusionMedia #DigitalMarketingMastery #FollowForInsights</span>"

                    </p>


                         </div>
            </div>

            
            <div className="cardaa cursor-scale" onMouseEnter={() => { }} onMouseLeave={() => { }}>


                <div className="imgBgaa">
                    <img src={images[currentImageIndex]} alt="bg" className="bgImage" />
                </div>




                <div className="topaa">
                    <div className="userDeatilsaa">
                        <div className="profileImgaa">
                        
                        <img src={phoenix} alt="user" className="coveraa" />

                        </div>
                        <h3>
                            FunkyFusionMedia
                            <br /><span>@funkyfusionmedia</span></h3>
                    </div>
                </div>
                <div className="btnsaa">
                    <div className="leftaa">
                    <img src={heartIcon} alt="heart" className="heartaa" onClick={likeButton} />
                    <img src={commentIcon} alt="comment" />
                    <b><img src={shareIcon} alt="share" /></b>
                    </div>

                    <div className="rightaa">
                    <img src={bookmarkIcon} alt="bookmark" />
                    </div>
                </div>
                <h4 className="likesaa">999 Likes</h4>
                <h4 className="messageaa">
                    <b>@funkyfusionmedia</b>
                    Elevating brands with a dash of creativity and a splash of innovation! Our funkyFusionMedia website is LIVE, and we're ready to rock the digital realm. Join us on this exciting journey as we fuse passion with strategy to amplify your brand's digital presence. Explore with us! 🌐✨ #FunkyFusionMedia #DigitalInnovation #ExploreWithUs"
                   
                </h4>
                

            </div>
        </section>
    );
};

export default InstaHighlights;
