// Testimonials.js
import React, { useEffect } from 'react';
import img1 from './img/1.jpg';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import { initializeCursorAnimation } from './CursorAnimation'; // Adjust the path accordingly


const Testimonials = () => {
  useEffect(() => {
    initializeCursorAnimation(); // Initialize the cursor animation when the component mounts

    // Additional useEffect code if needed

    return () => {
      // Cleanup code if needed
    };
  }, []);

  return (
    <section id="testimonials" className="section__container client__container">
      <p className="section__subheader">Testimonials</p>
      <h2 className="section__header">What clients say about us</h2>
      <div className="client__grid">
        <div className="client__card">
          <img src={img1} alt="client" />
          <h4>Love the simplicity</h4>
          <p className="cursor-scale">
            They understood our brand and created a stunning website design.
            Professional, responsive, and on-time delivery. Highly recommended!
          </p>
        </div>
        <div className="client__card">
          <img src={img2} alt="client" />
          <h4>Excellent Design and Service</h4>
          <p className="cursor-scale">
            Efficient, reliable, and results-oriented. Visually appealing
            website, improved online visibility. Highly recommended!
          </p>
        </div>
        <div className="client__card">
          <img src={img3} alt="client" />
          <h4>Efficient and Reliable</h4>
          <p className="cursor-scale">
            Best decision we made. Stunning website, exceptional support. Always
            available and prompt issue resolution. Hassle-free experience!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
