import React, { useEffect, useState } from 'react';
import { initializeCursorAnimation } from './CursorAnimation';

function NewsComponent() {
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const timelineItems = document.querySelectorAll(".timeline .timeline-item");

      timelineItems.forEach((item, index) => {
        const rect = item.getBoundingClientRect();

        // Check if the middle of the item is in the viewport
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          setActiveItem(index);
        }
      });

      const line = document.querySelector(".timeline");
      const timelineBar = document.querySelector(".timeline-bar");
      // ... existing code
    };

    document.addEventListener("scroll", handleScroll);

    // GSAP cursor animations for timeline items
    initializeCursorAnimation();

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [activeItem]);

  const timelineContent = [
    {
      title: "Web Wonder",
      description: "Funky design, where wonders brew. Engaging visuals, a website brand-new. Navigating the web with wonder, creating an online universe to ponder."
     },
    {
      title: "App Pride Parade",
      description: "Funky apps, a glide with pride. Loyalty blooms in every app stride. Crafting apps that lead the parade, where user experience is our accolade."
    },
    {
      title: "PPC Magic",
      description: "Funky PPC, a magical flick. Clicking success with every strategic pick. Transforming clicks into conversions, our magic is PPC perfection."

    },
    {
      title: "Email Jazz Extravaganza",
      description: "Funky emails, with a touch of pizzazz. Open the door to marketing jazz. We compose email symphonies, turning leads into a rhythmic dance."
    },
  ];


  return (
    <div className="ss">
    <div className="container mt-5 mb-5 container-center">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h4 className="news">Latest News</h4>
          <ul className="timeline">
            {timelineContent.map((item, index) => (
              <li key={index} className={`timeline-item cursor-scale ${activeItem === index ? 'active' : ''}`} onMouseEnter={() => { }} onMouseLeave={() => { }}>
                <h3 className="ppp">{item.title}</h3>
                <p className="pp">{item.description}</p>
              </li>
            ))}
          </ul>
          <div className="timeline-bar"></div>
        </div>
      </div>
    </div>
  </div>
);
}

export default NewsComponent;
