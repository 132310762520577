import React from 'react';
import logo from './img/phoenix.png';
import { Link, animateScroll as scroll } from 'react-scroll';

const Navbar = () => {
  const handleGetInTouchClick = () => {
    // Scroll to the ContactSection using react-scroll
    scroll.scrollTo('contactSection', {
      smooth: true,
      duration: 500,
    });
  };

  return (
    <nav className="section__container nav__container">
      <div className="nav__logo-container">
        <img src={logo} alt="Logo" className="nav__logo-img" />
        <div className="nav__logo">FunkyFusion Media</div>
      </div>

      <ul className="nav__links">
        <li className="link">
          <Link to="home" smooth={true} duration={500}>Home</Link>
        </li>
        <li className="link">
          <Link to="services" smooth={true} duration={500}>Services</Link>
        </li>
        <li className="link">
          <Link to="about-us" smooth={true} duration={500}>About Us</Link>
        </li>
        <li className="link">
          <Link to="blog" smooth={true} duration={500}>Blog</Link>
        </li>
        <li className="link">
          <Link to="testimonials" smooth={true} duration={500}>Testimonials</Link>
        </li>
      </ul>
    
      <button className="button" id="getInTouchButton" onClick={handleGetInTouchClick}>
      <Link to="contactSection" smooth={true} duration={500}>Get-In-Touch</Link>

 
      </button>
    </nav>
  );
};

export default Navbar;
