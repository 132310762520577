import React, { useEffect, useState } from 'react';

function Scroll() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    // Add event listener to show/hide scroll-to-top button
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const showButtonThreshold = 200; // Adjust as needed

      if (scrollY > showButtonThreshold) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {/* Your existing components */}
      {/* ... */}

      {/* Scroll-to-top button */}
      {showScrollButton && (
        <button className="buttonn" id="scrollToTopButton" onClick={scrollToTop}>
          <svg className="svgIcon" viewBox="0 0 384 512">
            <path
              d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
}

export default Scroll;
