import gsap from 'gsap';

export const initializeCursorAnimation = () => {
  var cursor = document.querySelector('.cursor');
  var cursorScale = document.querySelectorAll('.cursor-scale');
  var mouseX = 0;
  var mouseY = 0;

  gsap.to({}, 0.016, {
    repeat: -1,
    onRepeat: function () {
      if (cursor) {
        gsap.set(cursor, {
          css: {
            left: mouseX,
            top: mouseY
          }
        });
      }
    }
  });

  window.addEventListener("mousemove", function (e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
  });

  cursorScale.forEach(link => {
    link.addEventListener('mouseleave', () => {
      if (cursor) {
        cursor.classList.remove('grow');
        cursor.classList.remove('grow-small');
        cursor.classList.remove('hidden'); // Remove the hidden class
      }
    });
    link.addEventListener('mouseenter', () => {
      if (cursor) {
        cursor.classList.add('hidden'); // Add the hidden class
      }
    });
    link.addEventListener('mousemove', () => {
      if (cursor) {
        cursor.classList.add('grow');
        if (link.classList.contains('small')) {
          cursor.classList.remove('grow');
          cursor.classList.add('grow-small');
        }
      }
    });
  });
};